<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Zone Groups')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openZoneModal('add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large" 
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      v-model="selected"
      multiple
      :data="zoneGroups"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Zones')}}</shipblu-th>
        <shipblu-th>{{$t('Visit Days')}}</shipblu-th>
        <shipblu-th><feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/></shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="order-6" :data="data[indextr].name">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              {{ data[indextr].name }}
            </div>
          </shipblu-td>

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].zones">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zones')}}</p>
            <span :key="zone.index" v-for="zone in data[indextr].zones">
              {{ $t(zone.name) }}, 
            </span>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].days">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Visit Days')}}</p>
            <span :key="day.index" v-for="day in data[indextr].visit_days">
              {{ day.day }}, 
            </span>
          </shipblu-td>

          <shipblu-td class="order-2">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
              >
              <span class="material-symbols-outlined text-2xl">more_horiz</span> 
              <vs-dropdown-menu  style="width: 160px">
                  <vs-dropdown-item
                    @click="openZoneModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteZone(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <add-zone-group :zoneGroupModal="zoneGroupModal" :zones="zones" @zoneGroupModal="zoneGroupModal = $event" :zoneGroup="zoneGroup" :type="type" @loadZones="loadZoneGroup"></add-zone-group>
   </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import AddZoneGroup from './components/AddZoneGroup.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'

export default {
  data () {
    return {
      selected: [],
      zoneGroups: [],
      deleteData: {},
      zoneGroupModal: false,
      zoneGroup: {},
      type: '',
      tableLoader: false,
      zones: []
    }
  },
  watch: {
    '$route.params.warehouseID' () {
      this.loadZoneGroup()
      this.loadZones()
    } 
  },
  methods: {
    loadZoneGroup () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehouses/${this.$route.params.warehouseID}/zone-groups/`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.zoneGroups = response.data
          this.tableLoader = false
        }
      )
    },
    loadZones () {
      sendRequest(false, false, this, `api/v1/warehouses/${this.$route.params.warehouseID}/zones/`, 'get', null, true, 
        (response) => {
          this.zones = response.data
        }
      )
    },
    openZoneModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.zoneGroup = {
          name: '',
          zones: []
        } 
      } else {
        this.zoneGroup = data
      }
      this.zoneGroupModal = true
    },
    deleteZone (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/zone-groups/${this.deleteData.id}/`, 'delete', null, true, 
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Zone group'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadZoneGroup()
        }
      )
    }
  },
  components: {
    AddZoneGroup,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  created () {
    this.loadZoneGroup()
    this.loadZones()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
